<template>
    <div>

        <!-- Form: Info Form -->
        <validation-observer ref="simpleRules">
            <b-form>
                <b-row>

                    <!-- Field: Names -->
                    <b-col
                        cols="12"
                        md="4"
                    >
                        <b-form-group
                            label="Nombre(s)"
                            label-for="names"
                        >
                            <validation-provider
                                #default="{ errors }"
                                rules="required"
                                name="nombre(s)"
                            >
                                <b-form-input
                                    id="names"
                                    :state="errors.length > 0 ? false:null"
                                    v-model="reviserData.user.names"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <!-- Field: Surnames -->
                    <b-col
                        cols="12"
                        md="4"
                    >
                        <b-form-group
                            label="Apellido(s)"
                            label-for="surnames"
                        >
                            <validation-provider
                                #default="{ errors }"
                                rules="required"
                                name="apellido(s)"
                            >
                                <b-form-input
                                    id="surnames"
                                    :state="errors.length > 0 ? false:null"
                                    v-model="reviserData.user.surnames"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                </b-row>
                <b-row>

                    <!-- Field: Email -->
                    <b-col
                        cols="12"
                        md="4"
                    >
                        <b-form-group
                            label="Correo electrónico"
                            label-for="email"
                        >
                            <validation-provider
                                #default="{ errors }"
                                rules="required|email"
                                name="correo electrónico"
                            >
                                <b-form-input
                                    id="email"
                                    :state="errors.length > 0 ? false:null"
                                    v-model="reviserData.user.email"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <!-- Field: Password -->
                    <b-col
                        cols="12"
                        md="4"
                    >
                        <b-form-group
                            label="Contraseña (en caso desee actualizarla, complete el siguiente campo, sino, dejar vacío)"
                            label-for="a-password"
                        >
                            <b-form-input
                                id="a-password"
                                v-model="reviserData.password"
                                placeholder="********"
                                autocomplete="new-password"
                            />
                        </b-form-group>
                    </b-col>

                </b-row>
            </b-form>
        </validation-observer>

        <!-- Action Buttons -->
        <b-button
            @click="updateInformation"
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        >
            Guardar cambios
        </b-button>
    </div>
</template>

<script>

    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
    import { ValidationProvider, ValidationObserver } from 'vee-validate';
    import { useToast } from 'vue-toastification/composition';
    import { ref } from '@vue/composition-api';
    import { required } from '@validations';
    import store from '@/store';

    export default {
        components: {
            ValidationProvider,
            ValidationObserver,

            required
        },
        props: {
            reviserData: {
                type: Object,
                required: true
            }
        },
        setup(props) {

            // Use Toast
            const toast = useToast();

            const simpleRules = ref(null);

            const updateInformation = () =>
            {
                simpleRules.value.validate()
                    .then(success => {
                        if (success) {

                            let reviserData = {
                                ...props.reviserData.user,
                                password: props.reviserData.password
                            };


                            store.dispatch('app-reviser/updateReviser', { id: props.reviserData.id, reviserData })
                                .then((response) => {

                                    props.reviserData.password = '';

                                    toast({
                                        component: ToastificationContent,
                                        props: {
                                            title: response.data.message,
                                            icon: 'CheckIcon',
                                            variant: 'success'
                                        }
                                    });
                                })
                                .catch( (err) => {
                                    let message = err.response.data.message ? err.response.data.message : 'Error al actualizar la información del revisor.';
                                    toast({
                                        component: ToastificationContent,
                                        props: {
                                            title: message,
                                            icon: 'AlertTriangleIcon',
                                            variant: 'danger'
                                        }
                                    });
                                });

                        }
                    });
            };

            return {
                updateInformation,
                simpleRules
            }
        }
    }

</script>
