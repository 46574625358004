<template>
    <component :is="reviserData === undefined ? 'div' : 'b-card'">

        <b-alert variant="danger" :show="reviserData === undefined">
            <h4 class="alert-heading">
                Error al obtener los datos del revisor
            </h4>
            <div class="alert-body">
                No se encontró ningún revisor con este ID. Verificar en la
                <b-link class="alert-link" :to="{ name: 'revisers' }">Lista de revisores</b-link>
                para buscar a otro revisor
            </div>
        </b-alert>

        <b-tabs v-if="reviserData" pills>

            <b-tab active>
                <template #title>
                    <feather-icon
                        icon="InfoIcon"
                        size="16"
                        class="mr-0 mr-sm-50"
                    />
                    <span class="d-none d-sm-inline">Información</span>
                </template>
                <reviser-edit-tab-information
                    :reviser-data="reviserData"
                    class="mt-2 pt-75"
                />
            </b-tab>

        </b-tabs>
    </component>
</template>

<script>

    import { BAlert, BCard, BLink, BTab, BTabs } from 'bootstrap-vue';
    import {onUnmounted, ref} from '@vue/composition-api';
    import router from '@/router';
    import store from '@/store';
    import reviserStoreModule from '../reviserStoreModule';
    import ReviserEditTabInformation from './ReviserEditTabInformation';

    export default {
        components: {
            BTab,
            BTabs,
            BCard,
            BAlert,
            BLink,

            ReviserEditTabInformation
        },
        setup() {
            const reviserData = ref(null);

            const USER_APP_STORE_MODULE_NAME = 'app-reviser';

            if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, reviserStoreModule);

            onUnmounted(() => {
                if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME);
            });

            store.dispatch('app-reviser/fetchReviser', { id: router.currentRoute.params.id })
            .then(response => {
                if (response.data === 'error'){
                    reviserData.value = undefined;
                } else {
                    reviserData.value = {
                        password: '',
                        changePhoto: 0,
                        ...response.data
                    };
                }
            })
            .catch(error => {
                if (error.response.status === 404) {
                    reviserData.value = undefined;
                }
            });

            return {
                reviserData
            }
        }
    }

</script>